/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Utility function to require all files in a directory...
function requireAll(r) {
  r.keys().forEach(r);
}

// Add images to the manifest
requireAll(require.context("images/", true, /\.(svg|png)$/)); // eslint-disable-line no-undef

// Import our app CSS
import "css/application";